import React from "react"
import "../../../assets/scss/components/app-loader.scss"
import config from "../../../configs/config"
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100">
        <img className="fallback-logo" src={`${config.media_host}/logo.png`} alt="logo" width={200} />
        <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div>
      </div>
    )
  }
}

export default SpinnerComponent
