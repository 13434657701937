const config = {
    host: process.env.REACT_APP_HOST || 'http://localhost:3333/',
    base_url: process.env.REACT_APP_BASE_URL || 'http://localhost:3333/api/',
    token: process.env.REACT_APP_TOKEN || 'myapptoken',
    expire: process.env.REACT_APP_EXPIRE || 'expire',
    expireTime: process.env.REACT_APP_EXPIRE_TIME || 3600*1000*2,
    email: process.env.REACT_APP_EMAIL || 'info@beauty360.com',
    phone: process.env.REACT_APP_PHONE || '+1(123) 456-789',
    instagram_url: process.env.REACT_APP_INSTAGRAM_URL || 'https://www.instagram.com/beauty360/',
    name: process.env.REACT_APP_NAME || 'Beauty360',
    locations: process.env.REACT_APP_LOCATIONS || 'beauty360',
    services: process.env.REACT_APP_SERVICES || '*',
    media_host: process.env.REACT_APP_MEDIA_HOST || '',
    language: process.env.REACT_APP_LANGUAGE || 'en',

    servicesConfigs: Object.fromEntries(
        Object.entries(process.env)
            .filter(([key, value]) => key.includes('SERVICE_CONFIG'))
            .map(([key, value]) => [key.replace('REACT_APP_SERVICE_CONFIG_').toLowerCase(), value])
    )
}

export default config
