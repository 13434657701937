import React from "react"
import * as Icon from "react-feather"
import { i18nKey } from "./language"

const horizontalMenuConfig = [
  {
    id: "home",
    title: i18nKey('menu.horizontal.page.home'),
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "page2",
    title: i18nKey('menu.horizontal.page.2'),
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/page2",
  }  
]

export default horizontalMenuConfig
