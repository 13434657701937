import config from "./config";

const LanguageData = {
	"es": {
		"login.title": "Ingresar",
		"login.subtitle": "Bienvenido, por favor ingresa su cuenta.",
		"login.form.email": "Correo",
		"login.form.password": "Contraseña",
		"login.form.location": "Ubicacion",
		"login.form.rememberme": "Recordarme",
		"login.forgot": "Olvidate la Contraseña?",
		"login.form.button": "Ingresar",
		"register.title": "Crear Cuenta",
		"register.subtitle": "Complete el siguiente formulario para crear su cuenta.",
		"register.form.name": "Nombre",
		"register.form.email": "Correo",
		"register.form.password": "Contraseña",
		"register.form.passwordConfirm": "Repetir Contraseña",
		"register.form.location": "Ubicacion",
		"register.button.login": "Ingresar",
		"register.button.register": "Crear Cuenta",
		"notFound.title": "404 - Pagina no encontrada!",
		"notFound.subtitle": "Oops.… Parece que el link ingresado no es valido.",
		"notFound.contact": `Por favor contactar con ${config.name} en ${config.phone} o ${config.email}`,
		"home.title": "Generar URL Temporal",
		"home.form.service": "Servicio",
		"home.form.url.label": "URL Temporal Generada",
		"home.form.url.placeholder": "URL Generada",
		"home.form.sessionTime": "Tiempo de Sesión",
		"home.form.expireTime": "Tiempo Expiracion",
		"home.buttons.generate": "Generar",
		"home.buttons.copyUrl": "Copiar URL",
		"home.buttons.urlOpened": "¿URL Abierta?",
		"home.buttons.timeRemaining": "Tiempo Restante",
		"home.url.copySuccess": "Texto Copiado Correctamente",
		"home.error.noGeneratedUrl": "Esta url no es generada",
		"services.laseHairRemoval": "Depilación Láser",
		"services.permanentMakeup": "Maquillaje Permanente",
		"services.scalpPigmentation": "Pigmentación del Cuero Cabelludo",
		"services.skinPigmentation": "Pigmentación de la Piel",
		"services.tattooing": "Tatuaje",
		"services.chemicalPeels": "Peelings Químicos",
		"services.naturalPeels": "Peelings Naturales",
		"services.microneedling": "Microneedling",
		"services.facial": "Facial",
		"services.sclerotherapyVaricoseSpiderVeins": "Escleroterapia (Venas Varicosas/ Arañas Vasculares)",
		"services.botoxInjections": "Inyecciones de Botox",
		"services.dermalFillers": "Rellenos Dérmicos",
		"services.microdermabrasion": "Microdermoabrasión",
		"services.bodyContouring": "Moldeo Corporal",
		"services.facialRejuvenation": "Rejuvenecimiento Facial",
		"footer.allRigths": "Todos los derechos reservados",
		"footer.crafted": "Hecho a mano y con",
		"account.profile.available": "Disponible",
		"menu.horizontal.page.home": "Inicio",
		"menu.horizontal.page.2": "Pagina 2",
		"menu.navigation.new_client": "Nuevo Cliente",
		"menu.navigation.client_list": "Lista de Clientes",
		"menu.navigation.settings": "Configuraciones",
		"menu.navigation.sessions": "Sesiones",
		"customerList.buttons.export": "Exportar",
		"customerList.table.column.name": "Nombre",
		"customerList.table.column.email": "Correo",
		"customerList.table.column.phone": "Telefono",
		"customerList.table.column.city": "Ciudad",
		"customerList.table.column.firstSession": "Primera Sesión",
		"customerList.table.column.lastSession": "Ultima Sesión",
		"customerList.table.column.referredBy": "Referido Por",
		"customerList.table.column.consentForm": "Formulario de Consentimiento",
		"customerList.table.column.actions": "Acciones",
		"customerList.alerts.confirmDelete": "¿Está seguro de eliminar el registro?",
		"customerList.alerts.descriptionDelete": "¡No podrá revertir esto!",
		"customerList.messages.continue": "¡Sí, Enviar!",
		"customerList.messages.cancel": "Cancelar",
		"list.data.NotFound": "No hay datos registrados",
		"customerList.form.title.update": "ACTUALIZAR DATOS",
		"customerList.form.title.new": "AGREGAR DATOS",
		"customerList.button.update": "Actualizar",
		"customerList.button.submit": "Guardar",
		"customerList.alert.cancel": "Cancelar",
		"sessions.button.addNew": "Agregar Nuevo",
		"sessions.input.select": "Seleccionar",
		"sessions.field.date": "Fecha",
		"sessions.field.area": "Area",
		"sessions.field.skinType": "Tipo de Piel",
		"sessions.field.kj": "KJ",
		"sessions.field.cost": "Costo",
		"sessions.field.comments": "Comentarios",
		"sessions.field.colorUsed": "Color Usado",
		"sessions.field.technique": "Tecnica",
		"sessions.field.pigment_used": "Pigmentacion Usada",
		"sessions.field.session_duration": "Duracion de la Sesion",
		"sessions.field.type_of_pigmentation": "Tipo de Pigmentacion",
		"sessions.field.ink_used": "Ink Usado",
		"sessions.field.type_of_peel": "Tipo de Piel",
		"sessions.field.duration": "Duracion",
		"sessions.field.needle_depth": "Profundidad de la aguja",
		"sessions.field.serum_used": "Suero utilizado",
		"sessions.field.products_used": "Productos Usados",
		"sessions.field.sclerosing_agent_used": "Agente esclerosante utilizado",
		"sessions.field.pulses": "Pulsos",
		"sessions.field.units_used": "Unidades Usadas",
		"sessions.field.product_used": "Producto Usado",
		"sessions.field.amount_used": "Monto Usado",
		"sessions.field.type_of_crystals_used": "Tipo de Cristal Usado",
		"sessions.field.procedure": "Procedimiento",
		"sessions.field.actions": "Acciones",
		'sessions.text.chooseCustomer': "Elige el cliente.",
		"sessions.table.notData": "No hay registros para mostrar",
		"sessions.text.questionDelete": "¿Está seguro de eliminar el registro?",
		"sessions.text.confirmDelete": "¡No podrá revertir esto!",
		"sessions.button.confirm": "¡Sí, Enviar!",
		"sessions.button.cancel": "Cancelar",
		"sessions.aside.title.update": "ACTUALIZAR DATOS",
		"sessions.aside.title.new": "AGREGAR DATOS",
		"sessions.aside.button.update": "Actualizar",
		"sessions.aside.button.submit": "Guardar",
		"sessions.aside.button.cancel": "Cancelar",
		"settings.button.addNew": "Agregar",
		"settings.list.field.qustion": "Pregunta",
		"settings.list.field.category": "Categoria",
		"settings.list.field.name": "Nombre",
		"settings.list.field.time": "Tiempo",
		"settings.list.field.activate": "Activar",
		"settings.list.field.actions": "Acciones",
		"settings.list.noData": "No hay registros para mostrar",
		"settings.aside.options.check": "Check",
		"settings.aside.options.text": "Texto",
		"settings.aside.options.visible": "Visible",
		"settings.aside.options.invisible": "Invisible",
		"form.title.update": "ACTULIZAR DATA",
		"form.title.add": "AGREGAR DATA",
		"form.button.update": "Actualizar",
		"form.button.submit": "Guardar",
		"form.button.cancel": "Cancelar",
		"settings.title": "Titulo Formulario",
		"settings.section.bodyArea": "Cuerpo",
		"settings.section.basicHealthQuestions": "Pregustas Basicas",
		"settings.section.medicalHistoryQuestions": "Preguntas Medicas",
		"settings.section.consent1": "Texto de Consentimiento 1",
		"settings.section.consent2": "Texto de Consentimiento 2",
		"customer.sign.title": "Firme Aqui",
		"customer.sign.button.clear": "Restablecer",
		"customer.sign.button.sign": "Firmar",
		"customer.refferedBy.google": "Google/paginaweb",
		"customer.refferedBy.facebook": "Facebook",
		"customer.refferedBy.instagram": "Instagram",
		"customer.refferedBy.kijiji": "Kijiji",
		"customer.refferedBy.walkin": "Visita sin cita",
		"customer.refferedBy.existingClient": "Cliente existente",
		"customer.refferedBy.wordOfMouth": "De boca en boca",
		"customer.consent.title": "Consentimiento Laser Hair Removal",
		"customer.alert.signConsentForm": "Por favor firme el formulario de consentimiento",
		"customer.fields.date": "Fecha",
		"customer.fields.name": "Nombre",
		"customer.fields.hearAbout": "¿Cómo se enteró de nosotros?",
		"customer.fields.referrerName": "Referido Por",
		"customer.fields.nameSurname": "Nombre & Apellido",
		"customer.fields.email": "Correo",
		"customer.fields.dateBirth": "Fecha de Nacimiento",
		"customer.fields.address": "Direccion",
		"customer.fields.city": "Ciudad",
		"customer.fields.postal": "Codigo Postal",
		"customer.fields.phone": "Telefono",
		"customer.fields.areasTreated": "Areas a tratar",
		"customer.fields.comment": "Comentario",
		"customer.input.yes": "Si",
		"customer.input.no": "No",
		"customer.text.medicalHistory": "Historia Medica",
		"customer.text.confirm": "Si, Enviar!",
		"customer.text.signature": "Firma",
		"customer.button.submit": "Enviar",
		"customer.button.reset": "Resetear Formulario",
		"customer.button.cancel": "Cancelar",
		"customer.text.confirmRevert": "¿Está seguro de eliminar el registro?",
		"customer.text.pleaseWait": "Por favor espere",
		"customer.text.success": "Exitoso",
		"customer.text.waitMail": "Por favor espere, estamos guardando su formulario de consentimiento y enviándolo por correo electrónico.",
		"customer.text.successSaved1": "Su registro se ha guardado con éxito.",
		"customer.text.successSaved2": "Recibirá su formulario firmado por correo electrónico en breve.",
		"customer.text.deleted": "Borrado!",
		"customer.text.deletedDescription": "Tu archivo ha sido borrado.",
		"customer.text.cancelled": "Cancelado",
		"customer.text.cancelledDescripcion": "Tu imagen esta asegurada :)",
		"customer.text.areYouSure": "Estan correctos los datos?"
	},
	"en": {
		"login.title": "Login",
		"login.subtitle": "Welcome back, please login to your account.",
		"login.form.email": "Email",
		"login.form.password": "Password",
		"login.form.location": "Location",
		"login.form.rememberme": "Remember me",
		"login.forgot": "Forgot Password?",
		"login.form.button": "Login",
		"register.title": "Create Account",
		"register.subtitle": "Fill the below form to create a new account.",
		"register.form.name": "Name",
		"register.form.email": "Email",
		"register.form.password": "Password",
		"register.form.passwordConfirm": "Confirm Password",
		"register.form.location": "Location",
		"register.button.login": "Login",
		"register.button.register": "Register",
		"notFound.title": "404 - Page Not Found!",
		"notFound.subtitle": "Oops.… Looks like the link you are looking for is no longer valid.",
		"notFound.contact": `Please contact ${config.name} at ${config.phone} or ${config.email}`,
		"home.title": "Generate Temporary Url",
		"home.form.service": "Service",
		"home.form.url.label": "Generated Temporary Url",
		"home.form.url.placeholder": "Generated Url",
		"home.form.sessionTime": "Session Time",
		"home.form.expireTime": "Expire time",
		"home.buttons.generate": "Generate",
		"home.buttons.copyUrl": "Copy Url",
		"home.buttons.urlOpened": "URL opened?",
		"home.buttons.timeRemaining": "Time Remaining",
		"home.url.copySuccess": "Text Copied Successfully",
		"home.error.noGeneratedUrl": "There is no generated url",
		"services.laseHairRemoval": "Laser Hair Removal",
		"services.permanentMakeup": "Permanent Makeup",
		"services.scalpPigmentation": "Scalp Pigmentation",
		"services.skinPigmentation": "Skin Pigmentation",
		"services.tattooing": "Tattooing",
		"services.chemicalPeels": "Chemical Peels",
		"services.naturalPeels": "Natural Peels",
		"services.microneedling": "Microneedling",
		"services.facial": "Facial",
		"services.sclerotherapyVaricoseSpiderVeins": "Sclerotherapy (Varicose/Spider Veins)",
		"services.botoxInjections": "Botox Injections",
		"services.dermalFillers": "Dermal Fillers",
		"services.microdermabrasion": "Microdermabrasion",
		"services.bodyContouring": "Body Contouring",
		"services.facialRejuvenation": "Facial Rejuvenation",
		"footer.allRigths": "All rights reserved",
		"footer.crafted": "Hand-crafted & Made with",
		"account.profile.available": "Available",
		"menu.horizontal.page.home": "Home",
		"menu.horizontal.page.2": "Page 2",
		"menu.navigation.new_client": "New Client",
		"menu.navigation.client_list": "Client List",
		"menu.navigation.settings": "Settings",
		"menu.navigation.sessions": "Sessions",
		"customerList.buttons.export": "Export",
		"customerList.table.column.name": "Name",
		"customerList.table.column.email": "Email",
		"customerList.table.column.phone": "Phone",
		"customerList.table.column.city": "City",
		"customerList.table.column.firstSession": "First Session",
		"customerList.table.column.lastSession": "Last Session",
		"customerList.table.column.referredBy": "Referred By",
		"customerList.table.column.consentForm": "Consent Form",
		"customerList.table.column.actions": "Actions",
		"customerList.alerts.confirmDelete": "Are you sure delete record?",
		"customerList.alerts.descriptionDelete": "You won't be able to revert this!",
		"customerList.messages.continue": "Yes, Submit!",
		"customerList.messages.cancel": "Cancel",
		"list.data.NotFound": "There are no records to display",
		"customerList.form.title.update": "UPDATE DATA",
		"customerList.form.title.new": "ADD NEW DATA",
		"customerList.button.update": "Update",
		"customerList.button.submit": "Submit",
		"customerList.alert.cancel": "Cancel",
		"sessions.button.addNew": "Add New",
		"sessions.input.select": "Select",
		"sessions.field.date": "Date",
		"sessions.field.area": "Area",
		"sessions.field.skinType": "Skin Type",
		"sessions.field.kj": "KJ",
		"sessions.field.cost": "Cost",
		"sessions.field.comments": "Comments",
		"sessions.field.colorUsed": "Color User",
		"sessions.field.technique": "Technique",
		"sessions.field.pigment_used": "Pigment Used",
		"sessions.field.session_duration": "Session Duration",
		"sessions.field.type_of_pigmentation": "Type of Pigmentation",
		"sessions.field.ink_used": "Ink Used",
		"sessions.field.type_of_peel": "Type of Peel",
		"sessions.field.duration": "Duration",
		"sessions.field.needle_depth": "Needle Depth",
		"sessions.field.serum_used": "Serum Used",
		"sessions.field.products_used": "Products Used",
		"sessions.field.sclerosing_agent_used": "Sclerosing Agen Used",
		"sessions.field.pulses": "Pulses",
		"sessions.field.units_used": "Units Used",
		"sessions.field.product_used": "Product Used",
		"sessions.field.amount_used": "Amount Used",
		"sessions.field.type_of_crystals_used": "Type Of Cristal Used",
		"sessions.field.procedure": "Procedure",
		"sessions.field.actions": "Actions",
		'sessions.text.chooseCustomer': "Choose a customer.",
		"sessions.table.notData": "There are no records to display",
		"sessions.text.questionDelete": "Are you sure delete record?",
		"sessions.text.confirmDelete": "You won't be able to revert this!",
		"sessions.button.confirm": "Yes, Submit!",
		"sessions.button.cancel": "Cancel",
		"sessions.aside.title.update": "UPDATE DATA",
		"sessions.aside.title.new": "ADD NEW DATA",
		"sessions.aside.button.update": "Update",
		"sessions.aside.button.submit": "Submit",
		"sessions.aside.button.cancel": "Cancel",
		"settings.button.addNew": "Add New",
		"settings.list.field.qustion": "Question",
		"settings.list.field.category": "Category",
		"settings.list.field.activate": "Activate",
		"settings.list.field.name": "Name",
		"settings.list.field.time": "Time",
		"settings.list.field.actions": "Actions",
		"settings.list.noData": "There are no records to display",
		"settings.aside.options.check": "Check",
		"settings.aside.options.text": "Text",
		"settings.aside.options.visible": "Visible",
		"settings.aside.options.invisible": "Invisible",
		"form.title.update": "UPDATE DATA",
		"form.title.add": "ADD NEW DATA",
		"form.button.update": "Update",
		"form.button.submit": "Submit",
		"form.button.cancel": "Cancel",
		"settings.title": "Form Title",
		"settings.section.bodyArea": "Body Area",
		"settings.section.basicHealthQuestions": "Basic Health Questions",
		"settings.section.medicalHistoryQuestions": "Medical History Questions",
		"settings.section.consent1": "Consent Text 1",
		"settings.section.consent2": "Consent Text 2",
		"customer.sign.title": "Sign Here",
		"customer.sign.button.clear": "Clear",
		"customer.sign.button.sign": "Sign",
		"customer.refferedBy.google": "Google/website",
		"customer.refferedBy.facebook": "Facebook",
		"customer.refferedBy.instagram": "Instagram",
		"customer.refferedBy.kijiji": "Kijiji",
		"customer.refferedBy.walkin": "Walk-in",
		"customer.refferedBy.existingClient": "Existing client",
		"customer.refferedBy.wordOfMouth": "Word of mouth",
		"customer.consent.title": "Consent Laser Hair Removal",
		"customer.alert.signConsentForm": "Please sign the consent form.",
		"customer.fields.date": "Date",
		"customer.fields.name": "Name",
		"customer.fields.hearAbout": "How did you hear about us?",
		"customer.fields.referrerName": "Referrer's Name",
		"customer.fields.nameSurname": "Name & Surname",
		"customer.fields.email": "Email",
		"customer.fields.dateBirth": "Date of birth",
		"customer.fields.address": "Address",
		"customer.fields.city": "City",
		"customer.fields.postal": "Postal Code",
		"customer.fields.phone": "Phone",
		"customer.fields.areasTreated": "Areas to be treated",
		"customer.fields.comment": "Comment",
		"customer.input.yes": "Yes",
		"customer.input.no": "No",
		"customer.text.medicalHistory": "Medical History",
		"customer.text.confirm": "Yes, Submit!",
		"customer.text.signature": "Signature",
		"customer.button.submit": "Submit",
		"customer.button.reset": "Reset Form",
		"customer.button.cancel": "Cancel",
		"customer.text.confirmRevert": "You won't be able to revert this!",
		"customer.text.pleaseWait": "Please wait",
		"customer.text.success": "Success",
		"customer.text.waitMail": "Please wait, we are saving your consent form and sending via email.",
		"customer.text.successSaved1": "Your record has been successfully saved.",
		"customer.text.successSaved2": "You will receive your signed form via email shortly.",
		"customer.text.deleted": "Deleted!",
		"customer.text.deletedDescription": "Your file has been deleted.",
		"customer.text.cancelled": "Cancelled",
		"customer.text.cancelledDescripcion": " Your imaginary file is safe :)",
		"customer.text.areYouSure": "Are you sure?"
	}
}

export const i18nKey = (key) => {
    return LanguageData[config.language][key] || ''
}