import React from "react"
import * as Icon from "react-feather"
import { i18nKey } from "./language"
const navigationConfig = [
  {
    id: "home",
    title: i18nKey('menu.navigation.new_client'),
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "customer_list",
    title: i18nKey('menu.navigation.client_list'),
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/customers"
  },
  {
    id: "settings",
    title: i18nKey('menu.navigation.settings'),
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/settings"
  },
  {
    id: "past_sessions",
    title: i18nKey('menu.navigation.sessions'),
    type: "item",
    icon: <Icon.Clock size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/past_sessions"
  }
]

export default navigationConfig
