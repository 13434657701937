const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  newGeneratedUrl: ''
}

const getIndex = (arr, arr2, arr3, params = {}) => {
  if (arr2.length > 0) {
    let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
    let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
    let finalArr = [startIndex, endIndex]
    return (arr3 = finalArr)
  } else {
    let finalArr = [arr.length - parseInt(params.perPage), arr.length]
    return (arr3 = finalArr)
  }
}

  

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GENERATE_URL":
      return { ...state, newGeneratedUrl: action.url }
      case "GET_CUSTOMER_DATA":
        return {
          ...state,
          data: action.data,
          totalPages: action.totalPages,
          params: action.params,
          sortIndex: getIndex(
            action.data,
            action.data,
            state.sortIndex,
            action.params
          )
        }
      case "GET_CUSTOMER_ALL_DATA":
        return {
          ...state,
          bodyarea: action.bodyarea,
          allData: action.data,
          totalRecords: action.data.length,
          sortIndex: getIndex(action.data, state.data, state.sortIndex)
        }
      case "FILTER_CUSTOMER_DATA":
        let value = action.value
        let filteredData = []
        if (value.length) {
          filteredData = state.allData
            .filter(item => {
              return item.name?.toLowerCase().includes(value?.toLowerCase() || '') || 
                item.time?.toLowerCase().includes(value?.toLowerCase() || '') ||
                item.email?.toLowerCase().includes(value?.toLowerCase() || '')
            })
            .slice((state?.params?.page || 1) - 1, state?.params?.perPage || 20)
          return { ...state, filteredData }
        } else {
          filteredData = state.data
          return { ...state, filteredData }
        }
      case "ADD_CUSTOMER_DATA":
        state.data.push(action.data)
        return { ...state }
      case "DELETE_CUSTOMER_DATA":
        let index = state.data.findIndex(item => item.id === action.obj.id)
        let updatedData = [...state.data]
        updatedData.splice(index, 1)
        return {
          ...state,
          data: updatedData,
          totalRecords: state.allData.length,
          sortIndex: getIndex(
            state.allData,
            state.data,
            state.sortIndex,
            state.params
          )
        }
      default:
        return state
  }
}

export default customerReducer  
